<template>
  <div v-if="!autoplugTweets" class="h-75vh flex items-center justify-center">
    <half-circle-spinner :size="72" :color="'#3f51b5'" style="margin: auto;" />
  </div>
  <div v-else-if="autoplugTweets">
    <div>
      <div>
        <h1 class="mb-6">Autoplugs</h1>
        <template v-if="notSortedAutoplugTweets.length === 0">
          <span v-if="!isAutoplugEnabled" class="text-base">You haven't set any autoplug yet. Click
            <a href="javascript:;" @click="showAutoplugModal" class="text-main-color-100">here</a> to add one.</span
          >
          <span class="text-base" v-else>Not enough data has been collected. Please come back later.</span>
        </template>
        <template v-else>
          <div class="space-y-6">
            <div v-bind:key="autoplugTweet.id" v-for="autoplugTweet in autoplugTweets">
              <template>
                <div class="bg-white app-create-modal border border-gray-90 rounded-2xl ">
                  <div class="px-6 py-4 dark-mode-border-60 border-b border-gray-90">
                    <p
                      class="whitespace-pre-wrap break-word text-md"
                      v-html="formatTweet(autoplugTweet.status)"
                      dir="auto"
                    />
                  </div>

                  <div class="card-meta py-3 px-6">
                    <tooltip
                      :content="`Grab a ${getPlanLabel(
                        'standard'
                      )} Hypefury subscription to see your tweets' statistics`"
                      v-if="isUserPlanBasicOrBelow(userProfile.customerStatus)"
                    >
                      <div class="space-x-3" style="filter: blur(3px);">
                        <div>
                          <inline-svg src="/img/icons/retweet.svg" />
                          <span>1</span>
                          <span>
                            / 1
                          </span>
                        </div>
                        <div>
                          <inline-svg src="/img/icons/like.svg" />
                          <span>30</span>
                        </div>
                        <div>
                          <inline-svg src="/img/icons/impressions.svg" />
                          <span>9.7K</span>
                        </div>
                        <div>
                          <inline-svg src="/img/icons/reply.svg" />
                          <span>5</span>
                        </div>
                        <div>
                          <inline-svg src="/img/icons/profile_clicks.svg" />
                          <span>34</span>
                        </div>
                      </div>
                    </tooltip>

                    <div v-else class="space-x-3 flex items-center relative">
                      <tooltip
                        content="Retweets / Quotes"
                        :footer="getTweetMetricsUpdatedAtText(autoplugTweet)"
                      >
                        <div class="flex items-center gap-1 text-sm">
                          <inline-svg src="/img/icons/retweet.svg" />
                          <span
                            >{{ countFormatter(autoplugTweet.retweetCount, 1) }}/{{
                              countFormatter(autoplugTweet.quoteCount, 1)
                            }}</span
                          >
                        </div>
                      </tooltip>
                      <tooltip content="Likes" :footer="getTweetMetricsUpdatedAtText(autoplugTweet)">
                        <div class="flex items-center gap-1 text-sm">
                          <inline-svg src="/img/icons/like.svg" />
                          <span>{{ countFormatter(autoplugTweet.favoriteCount, 1) }}</span>
                        </div>
                      </tooltip>
                      <tooltip
                        content="Impressions"
                        :footer="getTweetMetricsUpdatedAtText(autoplugTweet)"
                      >
                        <div class="flex items-center gap-1 text-sm">
                          <inline-svg src="/img/icons/impressions.svg" />
                          <span>{{ countFormatter(autoplugTweet.impressions, 1) }}</span>
                        </div>
                      </tooltip>
                      <tooltip content="Replies" :footer="getTweetMetricsUpdatedAtText(autoplugTweet)">
                        <div class="flex items-center gap-1 text-sm">
                          <inline-svg src="/img/icons/reply.svg" />
                          <span>{{ countFormatter(autoplugTweet.replyCount, 1) }}</span>
                        </div>
                      </tooltip>
                      <tooltip
                        content="Bookmarks"
                        :footer="getTweetMetricsUpdatedAtText(autoplugTweet)"
                      >
                        <div class="flex items-center gap-1 text-sm">
                          <inline-svg src="/img/icons/new/bookmarks.svg" />
                          <span>{{ countFormatter(autoplugTweet.bookmarkCount, 1) }}</span>
                        </div>
                      </tooltip>
                      <tooltip
                        content="Profile Clicks"
                        :footer="getTweetMetricsUpdatedAtText(autoplugTweet)"
                      >
                        <div class="flex items-center gap-1 text-sm">
                          <inline-svg src="/img/icons/profile_clicks.svg" />
                          <span>{{ countFormatter(autoplugTweet.userProfileClicks, 1) }}</span>
                        </div>
                      </tooltip>
                      <tooltip
                        content="URL Link Clicks"
                        :footer="getTweetMetricsUpdatedAtText(autoplugTweet)"
                        v-if="autoplugTweet.urlLinkClicks > 0"
                      >
                        <div class="flex items-center gap-1 text-sm">
                          <inline-svg src="/img/icons/url_clicks.svg" />
                          <span>{{ countFormatter(autoplugTweet.urlLinkClicks, 1) }}</span>
                        </div>
                      </tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <upgrade-to-premium-pop-up
          :show="showUpgradeToPremiumPopUp"
          :content="`Upgrade to the ${getPlanLabel(
            'premium'
          )} plan and unlock search filters and ton of other powerful features!`"
          @continue-operation="continuePremiumFeature"
          @close="showUpgradeToPremiumPopUp = false"
        />
        <autoplug-modal
          :show="isAutoplugModalVisible"
          :shouldEnableAutoplugsWhileSaving="true"
          @close="closeAutoplugModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SwalModalMixin from './Mixins/SwalModalMixin.vue';
  import { HalfCircleSpinner } from 'epic-spinners';
  import { formatTweet } from '@/util/formatTweet';
  import dao from '@/dao';
  import lodash from 'lodash';
  import { countFormatter } from '@/util/countFormatter';
  import moment from 'moment';
  import 'moment-timezone';
  import { Thread } from '@/models/Thread';
  import Tooltip from '@/components/Tooltip.vue';
  import UpgradeToPremiumPopUp from '@/components/UpgradeToPremiumPopUp.vue';
  import GlobalSettingsMixin from './Mixins/GlobalSettingsMixin.vue';
  import AutoplugModal from './Modals/AutoplugModal';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';

  export default {
    components: { HalfCircleSpinner, Tooltip, UpgradeToPremiumPopUp, AutoplugModal },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      autoplugTweets() {
        if (this.notSortedAutoplugTweets) {
          const searchFilteredTweets = this.notSortedAutoplugTweets.filter((tweet) =>
            tweet.status.toLowerCase().includes(this.searchInput.toLowerCase())
          );

          return this.selectedFilter === 'all'
            ? searchFilteredTweets
            : lodash.orderBy(searchFilteredTweets, this.selectedFilter, 'desc');
        } else {
          return null;
        }
      },
      isAutoplugEnabled() {
        return lodash.get(this.userProfile, 'settings.autoplugConditions.autoplugEnabled', false);
      },
    },
    async mounted() {
      this.$eventStore.visitAutoplugsMetricsPage();

      const response = await dao.posts.getPostedAutoplugs(this.userProfile.uid);

      const posts = response.docs.map((post) =>
        Thread.buildFromFirestore(post, this.userProfile.timezone)
      );

      const groupedTweets = lodash.groupBy(posts, 'autoplug.status');
      const tweetsWithAccumulatedMetrics = Object.entries(groupedTweets).map((tweet) => ({
        status: tweet[0],
        tweetMetricsUpdatedAt: tweet[1].length ? tweet[1][0].tweetMetricsUpdatedAt : null,
        ...tweet[1].reduce(
          (previousValue, currentValue) => ({
            favoriteCount:
              previousValue.favoriteCount + lodash.get(currentValue, 'autoplug.favoriteCount', 0),
            retweetCount:
              previousValue.retweetCount + lodash.get(currentValue, 'autoplug.retweetCount', 0),
            impressions:
              previousValue.impressions + lodash.get(currentValue, 'autoplug.impressions', 0),
            quoteCount:
              previousValue.quoteCount + lodash.get(currentValue, 'autoplug.quoteCount', 0),
            replyCount:
              previousValue.replyCount + lodash.get(currentValue, 'autoplug.replyCount', 0),
            bookmarkCount:
              previousValue.bookmarkCount + lodash.get(currentValue, 'autoplug.bookmarkCount', 0),
            userProfileClicks:
              previousValue.userProfileClicks +
              lodash.get(currentValue, 'autoplug.userProfileClicks', 0),
            urlLinkClicks:
              previousValue.urlLinkClicks + lodash.get(currentValue, 'autoplug.urlLinkClicks', 0),
          }),
          {
            favoriteCount: 0,
            impressions: 0,
            quoteCount: 0,
            replyCount: 0,
            bookmarkCount: 0,
            userProfileClicks: 0,
            urlLinkClicks: 0,
            retweetCount: 0,
          }
        ),
      }));

      this.notSortedAutoplugTweets = tweetsWithAccumulatedMetrics;
    },
    data() {
      return {
        isLoading: false,
        notSortedAutoplugTweets: null,
        searchInput: '',
        selectedFilter: 'all',
        premiumFeatureOnHold: null,
        showUpgradeToPremiumPopUp: false,
        isAutoplugModalVisible: false,
      };
    },
    methods: {
      showAutoplugModal() {
        this.isAutoplugModalVisible = true;
      },
      closeAutoplugModal() {
        this.isAutoplugModalVisible = false;
      },
      countFormatter(num, digits) {
        return countFormatter(num, digits);
      },
      formatTweet(tweet) {
        return formatTweet(tweet);
      },
      setFilter(filter) {
        if (this.isUserPlanStandardOrBelow(this.userProfile.customerStatus)) {
          this.premiumFeatureOnHold = filter;
          this.showUpgradeToPremiumPopUp = true;
        } else {
          this.selectedFilter = filter;
        }
      },
      clearSearch() {
        this.searchInput = '';
      },
      getTweetMetricsUpdatedAtText(thread) {
        if (!thread.tweetMetricsUpdatedAt) return;
        const time = moment
          .tz(thread.tweetMetricsUpdatedAt.toDate(), this.userProfile.timezone)
          .fromNow();
        return `Updated ${time}`;
      },
      continuePremiumFeature() {
        this.setFilter(this.premiumFeatureOnHold);
      },
    },
    mixins: [SwalModalMixin, GlobalSettingsMixin, CustomerStatusMixin],
  };
</script>

<style lang="scss">
  .h-75vh {
    height: 75vh !important;
  }
</style>
