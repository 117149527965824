<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        isFirstTimeUser: false,
        isConditionalRetweetsModalVisible: false,
        isAutoplugModalVisible: false,
        isAutoShareOnInstagramModalVisible: false,
        isAutoUnretweeterModalVisible: false,
        isLinkedInAutoplugModalVisible: false,
      };
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isAutoplugEnabled() {
        return lodash.get(this, 'userProfile.settings.autoplugConditions.autoplugEnabled', false);
      },
      isInstagramAutoShareEnabled() {
        return lodash.get(this, 'userProfile.settings.instagramAutoShare.enabled', false);
      },
      areConditionalRetweetsEnabled() {
        return lodash.get(
          this,
          'userProfile.settings.conditionalRetweetsConditions.conditionalRetweetsEnabled',
          false
        );
      },
      isAutoRetweeterEnabled() {
        return lodash.get(this, 'userProfile.settings.autoUnretweeterSettings.enabled', false);
      },
      isLinkedInAutoplugEnabled() {
        return lodash.get(this, 'userProfile.settings.linkedInAutoplug.enabled', false);
      },
    },
    methods: {
      showAutoplugModal() {
        this.isAutoplugModalVisible = true;
      },
      closeAutoplugModal() {
        this.isAutoplugModalVisible = false;
      },
      showConditionalRetweetsModal() {
        this.isConditionalRetweetsModalVisible = true;
      },
      showConditionalRetweetsModalForFirstTimeUser() {
        this.isFirstTimeUser = true;
        this.isConditionalRetweetsModalVisible = true;
      },
      closeConditionalRetweetsModal() {
        this.isFirstTimeUser = false;
        this.isConditionalRetweetsModalVisible = false;
      },
      showAutoShareOnInstagramModal() {
        this.isAutoShareOnInstagramModalVisible = true;
      },
      closeAutoShareOnInstagramModal() {
        this.isAutoShareOnInstagramModalVisible = false;
      },
      showAutoUnretweeterModal() {
        this.isAutoUnretweeterModalVisible = true;
      },
      closeAutoUnretweeterModal() {
        this.isAutoUnretweeterModalVisible = false;
      },
      showLinkedInAutoplugModal() {
        this.isLinkedInAutoplugModalVisible = true;
      },
      closeLinkedInAutoplugModal() {
        this.isLinkedInAutoplugModalVisible = false;
      },
    },
  };
</script>
